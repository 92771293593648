<template>
  <div class="elv-report-transactions-header">
    <el-tabs v-model="tabActiveName" type="border-card" class="elv-report-transactions-tab" @tab-click="onChangeTab">
      <el-tab-pane name="reports-transactions-for-review" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('project.nav.forReview') }}<span>{{ props.reviewTotal }}</span>
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane
        :label="t('project.nav.reviewed')"
        name="reports-transactions-categorized"
        class="elv-report-detail-pane"
      >
        <template #label>
          <p>
            {{ t('project.nav.reviewed') }}<span>{{ utils.formatNumber(reportStore.reviewedTotal) }}</span>
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="reports-transactions-duplicates" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('project.nav.duplicates') }}<span>{{ props.duplicateTotal }}</span>
          </p>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script setup lang="ts">
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import { useReportStore } from '@/stores/modules/reports/index'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'

const props = defineProps({
  reviewTotal: {
    type: [Number, String],
    default: 0
  },
  duplicateTotal: {
    type: [Number, String],
    default: 0
  }
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const reportStore = useReportStore()
const menuGlobalStore = useMenuGlobalStore()

const tabActiveName = ref('reports-sources-detail')

// eslint-disable-next-line no-unused-vars
const headerWidth = computed(() => {
  return utils.isMobile() || !menuGlobalStore.isFixedMenu ? 'calc(100vw - 48px)' : 'calc(100vw - 179px)'
})

const onChangeTab = (tab: any) => {
  router.push({
    name: tab.props.name
  })
}

onMounted(() => {
  tabActiveName.value = String(route.name)
})
</script>

<style lang="scss" scoped>
.elv-report-transactions-header {
  position: relative;
}

:deep(.elv-report-transactions-tab) {
  border: 0px;

  .el-tabs__header {
    width: v-bind('headerWidth');
    margin-top: 8px;
    margin-bottom: 0px;
    background-color: #fff;
    border-bottom-color: #dde1e6;
  }

  .el-tabs__nav-wrap:after {
    height: 1px !important;
  }

  .el-tabs__nav.is-top {
    padding-left: 20px !important;
    display: flex;
  }

  .el-tabs__active-bar {
    background-color: $elv-color-1343BF;
  }

  .el-tabs__content {
    display: none;
  }

  .el-tabs__item.is-active {
    font-weight: 600;
    color: $elv-color-1343BF;
    border-right-color: #dde1e6;
    border-top-color: #dde1e6;
    border-left-color: #dde1e6;
  }

  .el-tabs__item {
    height: 34px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #636b75;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    margin-top: 0px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    margin-right: 6px;
    border-right-color: #edf0f3;
    border-top-color: #edf0f3;
    border-left-color: #edf0f3;

    &:not(.is-disabled):hover {
      color: $elv-color-1343BF;
    }

    &:nth-child(2) {
      padding-left: 12px !important;
    }

    &:last-child {
      padding-right: 12px !important;
    }

    span {
      margin-left: 6px;
      padding-left: 7px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      position: relative;

      &::after {
        width: 1px;
        height: 14px;
        background-color: #dde1e6;
        content: '';
        position: absolute;
        left: 0px;
        top: 2px;
      }
    }
  }
}
</style>
